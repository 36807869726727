import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"

const DEICommittee = ({ data }) => {
  return data.committeeCouncil.map(item => (
    <div className="bod_section" id={item.url} key={item.id}>
      <div className="fp_image">
        <img src="assets/image/About/fingure_print.png" alt="Fingureprint" />
      </div>
      <div className="container">
        <div className="bod_block">
          <div className="common_section_title">
            <h2>
              {item.headline1} <span>{item.headline2}</span>
              {/* <FormattedMessage
                id="dc"
                values={{ span: (...chunks) => <span>{chunks}</span> }}
              /> */}
            </h2>
            <p className="comittees-subtext">
              {item.info.info}
              {/* <FormattedMessage id="deiPara" /> */}
            </p>
          </div>
          <div className="bod_box_block">
            <div className="bod_box_inner">
              {item.members.map(member => (
                <div className="bod_box_col" key={member.id}>
                  <div className="bod_box show_modal">
                    <a href={member.socialMedia} target="_blank">
                      <div className="bod_img_box">
                        <img src={member.image.file?.url} />
                      </div>
                      <div className="bod_box_info">
                        <h5>{member.name}</h5>
                        <p>{member.designation}</p>                        
                        {member.additionalPosition !== "#" && (
                          <p className="officer-title">{member.additionalPosition}</p>
                        )}
                      </div>
                    </a>
                  </div>
                </div>
              ))}

              {/* <div className="bod_box_col">
                <div className="bod_box show_modal">
                  <a href="https://www.linkedin.com/in/paul-gittens-3960b62/">
                    <div className="bod_img_box">
                      <img src={Paul} />
                    </div>
                    <div className="bod_box_info">
                      <h5>Paul Gittens</h5>
                      <p>VP Sales - Allvision</p>
                      <p className="officer-title">CO-Chair</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="bod_box_col">
                <div className="bod_box show_modal">
                  <a href="https://www.linkedin.com/in/adrien-sossa-3390714/">
                    <div className="bod_img_box">
                      <img src={Adrien} />
                    </div>
                    <div className="bod_box_info">
                      <h5>Adrien Sossa</h5>
                      <p>VP Partnerships — Adomni</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="bod_box_col">
                <div className="bod_box show_modal">
                  <a href="https://www.linkedin.com/in/michelle-e-jairam-5a2a922/">
                    <div className="bod_img_box">
                      <img src={Michelle} />
                    </div>
                    <div className="bod_box_info">
                      <h5>Michelle Jairam</h5>
                      <p>Business Director - OMD</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="bod_box_col">
                <div className="bod_box show_modal">
                  <a href="https://www.linkedin.com/in/shannonschafer/">
                    <div className="bod_img_box">
                      <img src={Shannon} />
                    </div>
                    <div className="bod_box_info">
                      <h5>Shannon Schafer</h5>
                      <p>Director - Client Strategy - Mediology</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="bod_box_col">
                <div className="bod_box show_modal">
                  <a href="https://www.linkedin.com/in/michelle-lam-5b4ab02/">
                    <div className="bod_img_box">
                      <img src={MichelleLam} />
                    </div>
                    <div className="bod_box_info">
                      <h5>Michelle Lam</h5>
                      <p>Operations Manager - Stellar Outdoor</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="bod_box_col">
                <div className="bod_box show_modal">
                  <a href="https://www.linkedin.com/in/michelle-lam-5b4ab02/">
                    <div className="bod_img_box">
                      <img src={Kristie} />
                    </div>
                    <div className="bod_box_info">
                      <h5>Kristie Cordwin</h5>
                      <p>Sales Manager - Astral</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="bod_box_col">
                <div className="bod_box show_modal">
                  <a href="https://www.linkedin.com/in/mike-mukhtar-kamal-b1866a66/">
                    <div className="bod_img_box">
                      <img src={Mike} />
                    </div>
                    <div className="bod_box_info">
                      <h5>Mike Kamal</h5>
                      <p>Director - Business Solutions - Horizon Media</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="bod_box_col">
                <div className="bod_box show_modal">
                  <a href="https://www.linkedin.com/in/annadangelo1/">
                    <div className="bod_img_box">
                      <img src={Anna} />
                    </div>
                    <div className="bod_box_info">
                      <h5>Anna D'Angelo</h5>
                      <p>Marketing Director - OUTFRONT Media</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="bod_box_col">
                <div className="bod_box show_modal">
                  <a href="https://www.linkedin.com/in/julie-lal-b88a219b/">
                    <div className="bod_img_box">
                      <img src={Julie} />
                    </div>
                    <div className="bod_box_info">
                      <h5>Julie Lal</h5>
                      <p>Senior Account Executive - Vendo Media</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="bod_box_col">
                <div className="bod_box show_modal">
                  <a href="https://www.linkedin.com/in/rodneybisnath/">
                    <div className="bod_img_box">
                      <img src={Rodney} />
                    </div>
                    <div className="bod_box_info">
                      <h5>Rodney Bisnath</h5>
                      <p>Senior Manager, Data & Insights - COMMB</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="bod_box_col">
                <div className="bod_box show_modal">
                  <a href="https://www.linkedin.com/in/crystal-dhir-6bab495/">
                    <div className="bod_img_box">
                      <img src={Crystal} />
                    </div>
                    <div className="bod_box_info">
                      <h5>Crystal Dhir</h5>
                      <p>VP, Client Engagement - The Siroky Group </p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="bod_box_col">
                <div className="bod_box show_modal">
                  <a href="https://www.linkedin.com/in/jessica-keller-479013bb/">
                    <div className="bod_img_box">
                      <img src={Jessica} />
                    </div>
                    <div className="bod_box_info">
                      <h5>Jessica Bialczak</h5>
                      <p>
                        Executive Assistant & Operations Coordinator - COMMB
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            */}
            </div>
            <div className="loadmore_btn">
              <a href="#0" className="load_more">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
}

export default DEICommittee
